import './index.less';
import LogoIcon from "../../../assets/logo-icon.png";
import axios from "axios";
import {useState} from "react";
import Api from "../../../Api";

const screenHeight = document.documentElement.clientHeight;

interface Params {
    Account: string|null,
    AutoLogin: boolean,
    LoginType: string,
    Passwd: string|null,
}

const LoginPage = () => {
    const [loginParams, setLoginParams] = useState<Params>({
        Account: null,
        Passwd: null,
        LoginType: 'account',
        AutoLogin: true,
    });
    const LoginSubmit = () => {
        if(!loginParams.Account || !loginParams.Passwd){
            alert('请输入账号和密码!');
            return;
        }

        axios.post(Api.Login,loginParams).then((res) => {
            console.log(res);
            if(res.data.MsgCode === 100 && res.data.Data && res.data.Data.Token){
                localStorage.setItem(Api.TokenName,res.data.Data.Token);
                window.location.reload();
            }else{
                alert(res.data && res.data.Desc);
            }
        }).catch((error) => {
            console.log(error);
            alert('网络异常，请重试');
        })
    }

    return (
      <div className="login-page-box" style={{height: screenHeight}}>
          <div className="login-box">
              <div className="logo-box">
                  <img className="logo-img" src={LogoIcon} />
                  <div className="logo-name">
                      <span>无限盒子</span>
                      <span>Infinite Box</span>
                  </div>
              </div>
              <div className="login-input">
                  <input
                      type="text"
                      placeholder="请输入账号"
                      maxLength={16}
                      onChange={(e) => {
                        setLoginParams(JSON.parse(JSON.stringify({
                            ...loginParams,
                            Account: e.target.value,
                        })))
                      }}
                  />
                  <input
                      type="text"
                      placeholder="请输入密码"
                      onChange={(e) => {
                          setLoginParams(JSON.parse(JSON.stringify({
                              ...loginParams,
                              Passwd: e.target.value,
                          })))
                      }}
                  />
              </div>
              <div className="login-btn">
                  <span onClick={LoginSubmit}>登录</span>
              </div>
          </div>
      </div>
    );
}

export default LoginPage;