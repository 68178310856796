import React, {createRef, useState} from "react";
import RViewerJS from 'viewerjs-react';
import 'viewerjs-react/dist/index.css';

import mapImg from '../../assets/map.png';
import mapLineImg from '../../assets/map-line.png';

interface PropsData {
    type: string,
    isHandle: boolean,
    data: number[],
    switchMap: string|undefined,
    currentMap: string,
}

const viewerSizeData: any = {};
const mapUrl: any = {
    url: mapImg,
}

const deviceInfo: any = {
    type: 'PC',
};

if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    deviceInfo.type = 'phone';
} else if (/(Android)/i.test(navigator.userAgent)) {
    deviceInfo.type = 'phone';
} else {
    deviceInfo.type = 'PC';
}

const viewOptions = {
    inline: true,
    backdrop: false,
    button: false,
    toolbar: false,
    navbar: false,
    tooltip: false,
    title: false,
    loading: false,
    minWidth: 200,
    minHeight: 200,
    maxZoomRatio: deviceInfo.type === 'PC' ? 5 : 1,
    minZoomRatio: 0.1,
    rotatable: false,
    movable: true,
    zoomable: true,
    zoomOnTouch: true,
    toggleOnDblclick: false,
    inheritedAttributes: ['crossOrigin', 'decoding', 'isMap', 'loading', 'referrerPolicy', 'sizes', 'srcset', 'useMap'],
};

class RcViewerJs extends React.Component<PropsData>{
    constructor(props: any) {
        super(props);
    }

    viewerRef = createRef<any>();
    RcViewerRef = createRef<any>();

    setViewerData(){
        if(this.viewerRef.current.viewer === null){
            return;
        }
        if(!viewerSizeData['htmlSize'] || !viewerSizeData['imgData'] || !viewerSizeData['viewSize'] || !viewerSizeData['initialImageData']){
            viewerSizeData['htmlSize'] = this.viewerRef.current && this.viewerRef.current.viewer.containerData;
            viewerSizeData['imgData'] = this.viewerRef.current && this.viewerRef.current.viewer.imageData;
            viewerSizeData['viewSize'] = this.viewerRef.current && this.viewerRef.current.viewer.viewerData;
            viewerSizeData['initialImageData'] = this.viewerRef.current && this.viewerRef.current.viewer.initialImageData;
        }
    }

    shouldComponentUpdate(nextPops: any, nextState: any) {
        if(Object.keys(viewerSizeData).length < 1){
            this.setViewerData();
        }


        if(nextPops.switchMap !== undefined){
            switch (nextPops.switchMap){
                case "map":
                    mapUrl.url = mapImg;
                    break;
                case "line":
                    mapUrl.url = mapLineImg;
                    break;
            }

            if(nextPops.switchMap === 'map' || nextPops.switchMap === 'line'){
                return true;
            }
        }


        if(nextPops.isHandle){
            setTimeout(() => {
                this.onMethodAction(nextPops.type, nextPops.data);
            },650);
        }

        return false;
    }

    handleMethodAction(type: string, data: number[]){
        setTimeout(() => {
            this.onMethodAction(type, data,false);
        },650);
    }

    onMethodAction(type: string, data: number[], touch: boolean = true){
        let x: number,y:number;
        this.viewerRef.current.clientHeight = 300;
        const initWidth = viewerSizeData?.initialImageData?.width;
        const initHeight = viewerSizeData?.initialImageData?.height;
        const viewerWidth = viewerSizeData?.viewSize?.width;
        const viewerHeight = viewerSizeData?.viewSize?.height;

        switch (type){
            case "reset":
                this.viewerRef && this.viewerRef.current && this.viewerRef.current.viewer.reset();
                break;
            case "moveTo":
                // 计算偏移位置
                x = 0 - initWidth * (data[0] + 1) * data[1] + viewerWidth * 0.5;
                y = 0 - initHeight * (data[0] + 1) * data[2] + viewerHeight * 0.5;

                this.viewerRef && this.viewerRef.current && this.viewerRef.current.viewer.reset().zoom(data[0]).moveTo(x,y);
                break;
            case "zoom":
                this.viewerRef && this.viewerRef.current && this.viewerRef.current.viewer.reset().zoom(data[0]);
                break;
            case "zoom_moveTo":
                x = 0 - initWidth * (data[0] + 1) * data[1] + viewerWidth * 0.5;
                y = 0 - initHeight * (data[0] + 1) * data[2] + viewerHeight * 0.5;
                this.viewerRef && this.viewerRef.current && this.viewerRef.current.viewer.reset().zoom(data[0]).moveTo(x,y);
                break;
        }
    }

    render() {
       return (
           <div className="view-box"
                style={{
                    width: '100%',
                    height: '100%',
                }} ref={this.RcViewerRef}>
               <RViewerJS
                   className="map-container-main"
                   ref={this.viewerRef}
                   options={{
                       ...viewOptions,
                       container: this.RcViewerRef.current,
                   }}
               >
                   <img src={mapUrl.url} style={{width: 0, height: 0}} />
               </RViewerJS>
           </div>
       );
    };
}

export default RcViewerJs;