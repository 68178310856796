const HOST = 'https://qymap-api.himymeta.com';
const TokenName = 'tdonline-sale-token';
const UUIDName = 'tdonline-sale-user-uuid';

const Api = {
    TokenName: TokenName,
    Login: HOST+'/user/login',
    UserInfo: HOST+'/user/info',
    SaleBlockList: HOST+'/order/search',
    SaveSaleBlock: HOST+'/order/bill/save',
    SelectOrderInfo: HOST+'/order/bill/info',
    DelOrder: HOST+'/order/bill/delete',
}

export default Api;