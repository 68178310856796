import React from 'react';
import './App.css';
import Api from './Api';

import Main from "./pages/main/Home/index";
import Login from "./pages/main/Login/index";

const userToken = localStorage.getItem(Api.TokenName);

function App() {
  return (
    <div className="App">
        {
            userToken ? <Main /> : <Login />
        }
    </div>
  );
}

export default App;
