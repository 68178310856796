interface MapChildrenBlockItem {
    id: number,
    name: string,
    code?: string,
    positionRate: {
        x: number,
        y: number,
    },
}

interface MapConfigItem {
    id: number,
    name: string,
    positionRate: {
      x: number,
      y: number,
    },
    children: MapChildrenBlockItem[],
}

const MapConfig: MapConfigItem[] = [
    {
        id: 1013,
        name: '中央赛博CBD',
        positionRate: {
            x: .4845,
            y: .4431,
        },
        children: [
            {
                id: 14,
                name: '中央商圈',
                code: 'ZYSQ_001',
                positionRate: {
                    x: .4845,
                    y: .4431,
                },
            },
            {
                id: 21,
                name: '序列化城市',
                code: 'ZYSQ_002',
                positionRate: {
                    x: .4845,
                    y: .4431,
                },
            }
        ],
    },
    {
        id: 1001,
        name: '汉口一厂',
        positionRate: {
            x: 0.1557,
            y: 0.3570,
        },
        children: [],
    },
    {
        id: 1002,
        name: 'SPACEX',
        positionRate: {
            x: 0.1276,
            y: 0.5264,
        },
        children: [],
    },
    {
        id: 1003,
        name: '野玫瑰空地',
        positionRate: {
            x: 0.2152,
            y: 0.4513,
        },
        children: [],
    },
    {
        id: 1004,
        name: '国潮街',
        positionRate: {
            x: .2729,
            y: .2881,
        },
        children: [],
    },
    {
        id: 1005,
        name: '未知生物研究所',
        positionRate: {
            x: .3676,
            y: .2192,
        },
        children: [],
    },
    {
        id: 1006,
        name: '星公园',
        positionRate: {
            x: .3423,
            y: .3284,
        },
        children: [],
    },
    {
        id: 1007,
        name: '住宅区',
        positionRate: {
            x: .2884,
            y: .4971,
        },
        children: [],
    },
    {
        id: 1008,
        name: '陨石公园',
        positionRate: {
            x: .4854,
            y: .2590,
        },
        children: [],
    },
    {
        id: 1009,
        name: '金融街',
        positionRate: {
            x: .4281,
            y: .3059,
        },
        children: [],
    },
    {
        id: 1010,
        name: '商业街',
        positionRate: {
            x: .3819,
            y: .4245,
        },
        children: [],
    },
    {
        id: 1011,
        name: 'CYBER 风情街',
        positionRate: {
            x: .4280,
            y: .4364,
        },
        children: [],
    },
    {
        id: 1012,
        name: '中央空地',
        positionRate: {
            x: .5019,
            y: .3477,
        },
        children: [],
    },
    {
        id: 1014,
        name: '鸟鸣花园',
        positionRate: {
            x: .4510,
            y: .5954,
        },
        children: [],
    },
    {
        id: 1015,
        name: '数字人研究中心',
        positionRate: {
            x: .6321,
            y: .3437,
        },
        children: [],
    },
    {
        id: 1016,
        name: '文化中心',
        positionRate: {
            x: .5634,
            y: .4028,
        },
        children: [],
    },
    {
        id: 1017,
        name: '艺术城',
        positionRate: {
            x: .6244,
            y: .4859,
        },
        children: [],
    },
    {
        id: 1018,
        name: '九龙城',
        positionRate: {
            x: .5504,
            y: .5507,
        },
        children: [],
    },
    {
        id: 1019,
        name: '山胡桃公园',
        positionRate: {
            x: .6668,
            y: .5787,
        },
        children: [],
    },
    {
        id: 1020,
        name: '品牌概念城',
        positionRate: {
            x: .8026,
            y: .4849,
        },
        children: [],
    },
    {
        id: 1021,
        name: '别墅区',
        positionRate: {
            x: .7909,
            y: .6241,
        },
        children: [],
    },
    {
        id: 1022,
        name: 'CYBER ART',
        positionRate: {
            x: .7046,
            y: .6772,
        },
        children: [],
    },
    {
        id: 1023,
        name: '海洋艺术馆',
        positionRate: {
            x: .7903,
            y: .7804,
        },
        children: [],
    },
    {
        id: 1024,
        name: '天地重工',
        positionRate: {
            x: .8839,
            y: .6017,
        },
        children: [],
    },
];


export default MapConfig;