import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import './index.less';
import Api from "../../../Api";

import Draggable from "react-draggable";
import RcViewerJs from "../../component/RcViewerJS";
import mapConfig from './map-config.d';

import LogoIcon from "../../../assets/logo.png";
import LandscapeTip from "../../../assets/landscape-tip.gif";
import HeaderName from "../../../assets/new-logo-icon.png";
import HeaderBorder from "../../../assets/header_border.png";
import areaIcon_1 from "../../../assets/200011_icon_hkyc.png";
import areaIcon_2 from "../../../assets/200011_icon_spacex.png";
import areaIcon_3 from "../../../assets/200011_icon_ymgkd.png";
import areaIcon_4 from "../../../assets/200011_icon_gcj.png";
import areaIcon_5 from "../../../assets/200011_icon_wzswyjs.png";
import areaIcon_6 from "../../../assets/200011_icon_xgy.png";
import areaIcon_7 from "../../../assets/200011_icon_zzq.png";
import areaIcon_8 from "../../../assets/200011_icon_ysgy.png";
import areaIcon_9 from "../../../assets/200011_icon_jrj.png";
import areaIcon_10 from "../../../assets/200011_icon_syj.png";
import areaIcon_11 from "../../../assets/200011_icon_cyberfqj.png";
import areaIcon_12 from "../../../assets/200011_icon_zykd.png";
import areaIcon_13 from "../../../assets/200011_icon_zysb.png";
import areaIcon_14 from "../../../assets/200011_icon_nmhy.png";
import areaIcon_15 from "../../../assets/200011_icon_szryjzx.png";
import areaIcon_16 from "../../../assets/200011_icon_whzx.png";
import areaIcon_17 from "../../../assets/200011_icon_ysc.png";
import areaIcon_18 from "../../../assets/200011_icon_jlc.png";
import areaIcon_19 from "../../../assets/200011_icon_shthy.png";
import areaIcon_20 from "../../../assets/200011_icon_ppgnc.png";
import areaIcon_21 from "../../../assets/200011_icon_bsq.png";
import areaIcon_22 from "../../../assets/200011_icon_cyberart.png";
import areaIcon_23 from "../../../assets/200011_icon_hysyjd.png";
import areaIcon_24 from "../../../assets/200011_icon_tdzg.png";

import blockMap_1 from "../../../assets/small_map/block-map-1.png";
import blockMap_2 from "../../../assets/small_map/block-map-2.png";
import areaMap_1 from "../../../assets/small_map/200011_map_qk01.png";
import areaMap_2 from "../../../assets/small_map/200011_map_qk02.png";
import areaMap_3 from "../../../assets/small_map/200011_map_qk03.png";
import areaMap_4 from "../../../assets/small_map/200011_map_qk04.png";
import areaMap_5 from "../../../assets/small_map/200011_map_qk05.png";
import areaMap_6 from "../../../assets/small_map/200011_map_qk06.png";
import areaMap_7 from "../../../assets/small_map/200011_map_qk07.png";
import areaMap_8 from "../../../assets/small_map/200011_map_qk08.png";
import areaMap_9 from "../../../assets/small_map/200011_map_qk09.png";
import areaMap_10 from "../../../assets/small_map/200011_map_qk10.png";
import areaMap_11 from "../../../assets/small_map/200011_map_qk11.png";
import areaMap_12 from "../../../assets/small_map/200011_map_qk12.png";
import areaMap_13 from "../../../assets/small_map/200011_map_qk13.png";
import areaMap_14 from "../../../assets/small_map/200011_map_qk14.png";
import areaMap_15 from "../../../assets/small_map/200011_map_qk15.png";
import areaMap_16 from "../../../assets/small_map/200011_map_qk16.png";
import areaMap_17 from "../../../assets/small_map/200011_map_qk17.png";
import areaMap_18 from "../../../assets/small_map/200011_map_qk18.png";
import areaMap_19 from "../../../assets/small_map/200011_map_qk19.png";
import areaMap_20 from "../../../assets/small_map/200011_map_qk20.png";
import areaMap_21 from "../../../assets/small_map/200011_map_qk21.png";
import areaMap_22 from "../../../assets/small_map/200011_map_qk22.png";
import areaMap_23 from "../../../assets/small_map/200011_map_qk23.png";
import areaMap_24 from "../../../assets/small_map/200011_map_qk24.png";

const iconImgConf: any = {
    1001: areaIcon_1,
    1002: areaIcon_2,
    1003: areaIcon_3,
    1004: areaIcon_4,
    1005: areaIcon_5,
    1006: areaIcon_6,
    1007: areaIcon_7,
    1008: areaIcon_8,
    1009: areaIcon_9,
    1010: areaIcon_10,
    1011: areaIcon_11,
    1012: areaIcon_12,
    1013: areaIcon_13,
    1014: areaIcon_14,
    1015: areaIcon_15,
    1016: areaIcon_16,
    1017: areaIcon_17,
    1018: areaIcon_18,
    1019: areaIcon_19,
    1020: areaIcon_20,
    1021: areaIcon_21,
    1022: areaIcon_22,
    1023: areaIcon_23,
    1024: areaIcon_24,
};

const mapImgConf: any = {
    1001: {
        url: areaMap_1,
        widthRate: 40,
    },
    1002: {
        url: areaMap_2,
        widthRate: 43,
    },
    1003: {
        url: areaMap_3,
        widthRate: 43,
    },
    1004: {
        url: areaMap_4,
        widthRate: 33,
    },
    1005: {
        url: areaMap_5,
        widthRate: 43,
    },
    1006: {
        url: areaMap_6,
        widthRate: 43,
    },
    1007: {
        url: areaMap_7,
        widthRate: 43,
    },
    1008: {
        url: areaMap_8,
        widthRate: 43,
    },
    1009: {
        url: areaMap_9,
        widthRate: 43,
    },
    1010: {
        url: areaMap_10,
        widthRate: 22,
    },
    1011: {
        url: areaMap_11,
        widthRate: 28,
    },
    1012: {
        url: areaMap_12,
        widthRate: 43,
    },
    1013: {
        url: areaMap_13,
        widthRate: 35,
    },
    1014: {
        url: areaMap_14,
        widthRate: 45,
    },
    1015: {
        url: areaMap_15,
        widthRate: 43,
    },
    1016: {
        url: areaMap_16,
        widthRate: 43,
    },
    1017: {
        url: areaMap_17,
        widthRate: 40,
    },
    1018: {
        url: areaMap_18,
        widthRate: 34,
    },
    1019: {
        url: areaMap_19,
        widthRate: 36,
    },
    1020: {
        url: areaMap_20,
        widthRate: 43,
    },
    1021: {
        url: areaMap_21,
        widthRate: 43,
    },
    1022: {
        url: areaMap_22,
        widthRate: 38,
    },
    1023: {
        url: areaMap_23,
        widthRate: 60,
    },
    1024: {
        url: areaMap_24,
        widthRate: 23,
    },
};

const mapBlockImgConf: any = {
    14: blockMap_1,
    21: blockMap_2,
};

interface ActionData {
    mapId: number|null,
    type: string,
    data: number[],
    isHandle: boolean,
    currentMap: string,
}

interface SaleBlockItem {
    BlockID: number,
    Key: string,
    Leaf: number,
    Name: string,
    Num: number,
    ParentID: number,
    State: number,
    Status: number,
    Typ: number,
};

interface SaleBlockList {
    id: number,
    name: string,
    list: SaleBlockItem[],
}

// 空间大小列表
const zoneList: any = [
    {
        label: '请选择',
        value: 0,
    }
];
for (let i = 0; i < 26; i++){
    zoneList[i+1] = {
        label: i+1,
        value: i+1,
    }
}

const zoneTypeList = [
    {
        label: '专属',
        value: 1,
    },
    {
        label: '开放',
        value: 2,
    }
];

const saleTypeList = [
    {
        label: '预定',
        value: 1,
    },
    {
        label: '出售',
        value: 2,
    },
    {
        label: '租赁',
        value: 3,
    }
];

const FormInitData = {
    cityInfo: {},
    unitInfo: {},
    zoneInfo: {},
    zoneType: 0,
    saleType: 0,
    price: '',
    showPrice: true,
    info: '',
    phone: '',
};

const Main = () => {
    const [screenNumber,setScreenNumber] = useState<number>(window.orientation);
    const [bodyHeight, setBodyHeight] = useState<number>(document.documentElement.clientHeight);
    const [areaKey, setAreaKey] = useState<number|undefined>(undefined);
    const [blockKey, setBlockKey] = useState<number|undefined>(undefined);
    const [zoomRate, setZoomRate] = useState<number>(2);
    const [smallMapStatus, setSmallMapStatus] = useState(false);
    const [smallMapUrl, setSmallMapUrl] = useState<string|undefined>(undefined);
    const [smallBlockMapStatus, setSmallBlockMapStatus] = useState<boolean>(false);
    const [smallBlockMapId, setSmallBlockMapId] = useState<number|undefined>(undefined);
    const [hiddenStatus, setHiddenStatus] = useState<boolean>(false);
    const [actionInfo, setActionInfo] = useState<ActionData>({
        mapId: null,
        type: 'reset',
        isHandle: true,
        data: [],
        currentMap: 'map',
    });
    const [resText, setResText] = useState<string>('');
    const [resTextVisible, setResTextVisible] = useState<boolean>(false);

    const [mapType, setMapType] = useState<string|undefined>(undefined);
    const [rightSign, setRightSign] = useState<boolean>(false);
    const [saleBlockList,setSaleBlockList] = useState<SaleBlockList>({
        id: 0,
        name: '',
        list: [],
    });

    const [unitList, setUnitList] = useState<[]>([]);
    const [userInfo,setUserInfo] = useState<any>(undefined);
    const [orderFormStatus, setOrderFormStatus] = useState<boolean>(false);
    const [orderInfo, setOrderInfo] = useState<any>(FormInitData);


    let actionInfoRef = useRef(actionInfo);
    actionInfoRef.current = actionInfo;

    // document.body.addEventListener('touchmove', function (e) {
    //     e.preventDefault();
    // }, { passive: false });

    // window.addEventListener('touchmove', function (e) {
    //     e.preventDefault();
    // }, { passive: false });

    // 检测Token是否存在
    const checkTokenStatus = () => {
        const userToken = localStorage.getItem(Api.TokenName);
        if(!userToken){
            window.location.reload();
            return;
        }
    }

    useEffect(() => {
        axios.get(Api.UserInfo,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(Api.TokenName),
            }
        }).then((res) => {
            if(res.data.MsgCode === 100 && res.data.Data){
                setUserInfo(JSON.parse(JSON.stringify(
                    res.data.Data,
                )))
            }else{
                localStorage.removeItem(Api.TokenName);
                window.location.reload();
            }
        }).catch((error) => {
            console.log(error);
        })

        window.addEventListener("orientationchange", function() {
            let currentMap: string = actionInfoRef.current.currentMap;
            let num: number = window.orientation;
            setScreenNumber(num);

            if(num === 90 || num === -90){
                window.location.reload();
            }else{
                setBodyHeight(document.documentElement.clientHeight);
            }
        }, false);

        document.addEventListener("gesturestart", function (event) {
            event.preventDefault();
        });

        document.addEventListener("dblclick", function (event) {
            event.preventDefault();
        });


        let lastTouchEnd = 0;
        document.addEventListener("touchend",function (event) {
            let now = new Date().getTime();
            if (now - lastTouchEnd <= 300) {
                event.preventDefault();
            }
            lastTouchEnd = now;
        },false);
    },[]);

    // 获取地块的出售情况
    const getSaleBlockList = (id: number) => {
        checkTokenStatus();

        axios.post(
            Api.SaleBlockList,
            {
                id: id,
            },{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem(Api.TokenName),
                }
            }
        ).then((data: any) => {
            if(data && data.data.MsgCode === 100){
                let temp: any = saleBlockList;
                let tempList = data.data.Data.List;

                temp.id = tempList[0].ParentID;
                temp.name = tempList[0].Key.replace("_"+tempList[0].Name,"")
                temp.list = tempList;

                setOrderInfo(JSON.parse(JSON.stringify({
                    ...orderInfo,
                    cityInfo: {
                        id: temp.id,
                        name: temp.name,
                    }
                })));

                tempList.unshift({
                    Name: '请选择',
                    BlockID: 0,
                    Key: 0,
                    ParentID: 0,
                });

                setUnitList(JSON.parse(JSON.stringify(tempList)));
                setSaleBlockList(JSON.parse(JSON.stringify(temp)));
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    // 展示提示结果
    const msgTips = (text: string) => {
        setResText(text);
        setResTextVisible(!resTextVisible);
        setTimeout(() => {
            setResTextVisible(false);
        },1500);
    }

    // 提交地块订单
    const orderSubmit = () => {
        checkTokenStatus();

        // 判断新增
        if(!orderInfo.cityInfo.BillID){
            // 判断各个属性是否存在
            if(!orderInfo.cityInfo.id){
                msgTips('地块ID不存在，请重试');
                return;
            }

            // 判断地块是否选择
            if(!orderInfo.unitInfo.BlockID || orderInfo.unitInfo.BlockID === 0){
                msgTips('未选择单元地块，请选择');
                return;
            }

            // 判断空间尺寸
            if(!orderInfo.zoneInfo.value || orderInfo.zoneInfo.value === 0){
                msgTips('未选择空间面积编号，请选择');
                return;
            }
        }

        // 判断空间类型
        if(!orderInfo.zoneType || orderInfo.zoneType === 0){
            msgTips('未选择空间类型，请选择');
            return;
        }

        // 判断租售类型
        if(!orderInfo.saleType || orderInfo.saleType === 0){
            msgTips('未选择租售类型，请选择');
            return;
        }

        // 判断租售价格
        if(!orderInfo.price || orderInfo.price === '' || orderInfo.price === null){
            msgTips('未填写租售价格，请填写');
            return;
        }

        const params: any = {
            SpaceType: orderInfo.zoneType, // 空间类型
            SellType: orderInfo.saleType, // 租售形式
            Price: orderInfo.price+'', // 租售价格
            Remark: orderInfo.info, // 客户信息
            Cell: orderInfo.phone, // 联系电话
        };

        if(orderInfo.cityInfo.BillID){
            params.BillID = orderInfo.cityInfo.BillID;
        }else{
            params.BlockID = orderInfo.unitInfo.BlockID; // 地块ID
            params.Space = orderInfo.zoneInfo.value;  // 空间大小
        }

        axios.post(Api.SaveSaleBlock, params,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(Api.TokenName),
            }
        }).then((res) => {
            if(res && res.data.MsgCode === 100){
                msgTips('订单保存成功');
                getSaleBlockList(orderInfo.cityInfo.id);
                setOrderInfo(JSON.parse(JSON.stringify(FormInitData)));
                setOrderFormStatus(!orderFormStatus);
            }else{
                msgTips(res.data.Desc);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    // 查询订单详情，用于修改订单
    const SaleOrderInfo = (orderId: number) => {
        axios.post(Api.SelectOrderInfo, {
            BillID: orderId,
        },{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(Api.TokenName),
            }
        }).then((res) => {
            if(res && res.data.MsgCode === 100){
                let temp = res.data.Data;
                let unitSelectIdx = 0;
                // 查询地块单元信息
                let unitTemp: any = unitList.filter((item: any, idx: number) => {

                    if(item.BlockID === temp.BlockID){
                        unitSelectIdx = idx;
                        return item;
                    }
                });

                unitTemp = {
                    ...unitTemp[0],
                    selectIdx: unitSelectIdx,
                }

                setOrderInfo(JSON.parse(JSON.stringify({
                    cityInfo: {
                        id: orderInfo.cityInfo.id,
                        name: orderInfo.cityInfo.name,
                        BillID: temp.BillID,
                    },
                    unitInfo: unitTemp,
                    zoneInfo: zoneList[temp.Space],
                    zoneType: temp.SpaceType,
                    saleType: temp.SellType,
                    price: temp.Price,
                    showPrice: true,
                    info: temp.Remark,
                    phone: temp.Cell,
                })));

                setOrderFormStatus(!orderFormStatus);

            }
        }).catch((error) => {
            console.log(error);
        })
    }

    // 删除订单
    const DelOrder = (orderId: number) => {
        axios.post(Api.DelOrder, {
            BillID: orderId,
        },{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(Api.TokenName),
            }
        }).then((res) => {
            if(res && res.data.MsgCode === 100){
                msgTips('订单删除成功');
                getSaleBlockList(orderInfo.cityInfo.id);
                setOrderInfo(JSON.parse(JSON.stringify(FormInitData)));
                setOrderFormStatus(!orderFormStatus);
            }else{
                msgTips('订单删除失败,'+res.data.Desc);
            }
        }).catch((error) => {

        })
    }

    // 退出登录
    const Logout = () => {
        localStorage.removeItem(Api.TokenName);
        window.location.reload();
    }

    return (
        <div className="main" style={{height: (screenNumber === 0 || screenNumber === 180) ? 'auto' : bodyHeight}}>

            {
                resTextVisible && (
                    <div className="message-tips">
                        <span>{resText}！</span>
                    </div>
                )
            }

            <div className={['small-map', smallMapStatus ? 'small-map-display' : '',hiddenStatus ? 'small-map-hidden' : ''].join(' ')}
            >
                <img style={{width: actionInfo.mapId ? mapImgConf[actionInfo['mapId']]['widthRate'] + '%' : '0%', opacity: smallBlockMapStatus ? 0 : 1,}}
                    src={actionInfo.mapId ? mapImgConf[actionInfo['mapId']]['url'] : ''}
                />

                <div
                    className={["small-block-map", smallBlockMapStatus ? 'small-block-map-visible' : 'small-block-map-hidden'].join(' ')}
                    style={{visibility: smallBlockMapStatus ? 'visible' : 'hidden'}}
                >
                    <div className="small-block-map-img-box">
                        <img src={smallBlockMapId && mapBlockImgConf[smallBlockMapId]} />
                    </div>
                    <div className="small-block-map-img-content">
                        <div>
                            <div className="small-block-map-img-content-header">
                                <span>{saleBlockList.name}</span>
                            </div>
                            <div className="small-block-map-img-content-line" />
                            <div className="small-block-map-img-content-list">
                                {
                                    saleBlockList.list.length > 0 && saleBlockList.list.map((item: any,idx: number) => {
                                        return item.Status === 1 && (
                                            <span
                                                className={item.Modify === 1 ? 'y-sale-status' : ''}
                                                onClick={(e) => {
                                                    if(item.Modify === 1){
                                                        SaleOrderInfo(item.BillID);
                                                    }
                                                }}
                                                key={'block-sell-'+idx}
                                            >{item.Name}({saleTypeList[item.SellerType - 1].label})</span>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div className="small-block-map-img-content-btn">
                            <span
                                onClick={() => {
                                    const temp = FormInitData;
                                    temp.cityInfo = {
                                        id: orderInfo.cityInfo.id,
                                        name: orderInfo.cityInfo.name,
                                    };
                                    setOrderInfo(JSON.parse(JSON.stringify(temp)));
                                    setOrderFormStatus(!orderFormStatus);
                                }}
                            >编辑</span>
                        </div>

                    </div>
                </div>
            </div>

            {
                screenNumber === 0 ? (
                    <div className="landscape-tip">
                        <div className="landscape-tip-box">
                            <div className="logo-box">
                                <img src={LogoIcon} />
                            </div>
                            <img src={LandscapeTip} />
                            <span>温馨提示：横屏体验更佳哦!</span>
                        </div>
                    </div>
                ) : (
                    <div className="map-container">
                        <div className="header-main">
                            <div>
                                <img src={HeaderName} />
                                <div className="user-info">
                                    <span>{userInfo && userInfo.Name}</span>
                                    <span>|</span>
                                    <span onClick={Logout}>注销</span>
                                </div>
                            </div>
                            <img src={HeaderBorder} />
                        </div>
                        <div className="left-main" style={{zIndex: smallMapStatus ? 4 : 1 }}>
                            <div className="area-list">
                                {
                                    mapType === 'line' ? (
                                        <></>
                                    ) : (
                                        <div className="area-block-list">
                                            <div className="area-block-list-header">
                                                <span className="area-block-list-name">区域地图</span>
                                            </div>
                                            <div className="area-block-list-main">
                                                {
                                                    mapConfig && mapConfig.map((item, key) => {
                                                        return (
                                                            <span
                                                                key={key}
                                                                className={key === areaKey ? "area-block-list-item area-block-active" : "area-block-list-item"}
                                                                onClick={() => {
                                                                    setSmallMapStatus(false);
                                                                    setSmallBlockMapStatus(false);
                                                                    setBlockKey(undefined);
                                                                    setRightSign(!rightSign);
                                                                    setOrderFormStatus(false);

                                                                    setTimeout(() => {
                                                                        setRightSign(false);
                                                                    },600);

                                                                    if(actionInfo.mapId === item.id){
                                                                        setAreaKey(undefined);
                                                                        setActionInfo(JSON.parse(JSON.stringify({
                                                                            mapId: null,
                                                                            type: 'reset',
                                                                            isHandle: true,
                                                                            data: [],
                                                                            currentMap: 'map',
                                                                        })));
                                                                        return;
                                                                    }

                                                                    setSmallMapUrl(mapImgConf[item.id]);

                                                                    setMapType(item.id.toString());

                                                                    setActionInfo(JSON.parse(JSON.stringify({
                                                                        currentMap: 'map',
                                                                        mapId: item.id,
                                                                        type: 'moveTo',
                                                                        isHandle: true,
                                                                        data: [zoomRate,item.positionRate.x,item.positionRate.y],
                                                                    })));

                                                                    setAreaKey(key);

                                                                    setTimeout(() => {
                                                                        setSmallMapStatus(true);
                                                                    },500);
                                                                }}
                                                            >
                                                <img src={iconImgConf[item.id]} alt="" />
                                                <span>{item.name}</span>
                                            </span>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <b />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <RcViewerJs
                            currentMap={actionInfo.currentMap}
                            type={actionInfo.type}
                            isHandle={actionInfo.isHandle}
                            data={actionInfo.data}
                            switchMap={mapType}
                        />
                        <div
                            className={[
                                'right-main',
                                rightSign ? 'right-main-display' : '',
                            ].join(' ')}
                            style={{
                                zIndex: smallMapStatus ? 4 : 1,
                                display: (mapConfig && areaKey !== undefined && mapConfig[areaKey].children.length > 0) ? 'flex' : 'none'}}
                        >
                            <div
                                style={{display: areaKey !== undefined ? 'block' : 'none'}}
                                className='land-block-list'
                            >
                                <div className="land-block-list-header">
                                    {areaKey !== undefined && mapConfig[areaKey].name}
                                </div>
                                <div className="land-block-list-main">
                                    {
                                        mapConfig && areaKey !== undefined && mapConfig[areaKey].children.map((item, key) => {
                                            return (
                                                <span
                                                    key={'block_'+key}
                                                    className={key === blockKey ? "land-block-list-item land-block-list-item-active" : "land-block-list-item"}
                                                    onClick={() => {
                                                        setSmallMapStatus(false);
                                                        setSmallMapStatus(true);
                                                        setOrderFormStatus(false);

                                                        if(item.id === smallBlockMapId){
                                                            if(smallBlockMapStatus){
                                                                setBlockKey(undefined);
                                                                setSmallBlockMapStatus(false);
                                                                setSmallBlockMapId(undefined);
                                                                return;
                                                            }else{
                                                                setSmallBlockMapStatus(true);
                                                                setSmallBlockMapId(item.id);
                                                            }
                                                        }else{
                                                            setSmallBlockMapStatus(true);
                                                            setSmallBlockMapId(item.id);
                                                        }
                                                        getSaleBlockList(item.id);
                                                        setBlockKey(key);
                                                    }}
                                                >{item.name}</span>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="bottom-main">
                            <div className="map-switch">
                                <button className={actionInfo.currentMap === 'map' ? "selected-map-type" : ''}
                                        onClick={() => {
                                            if(actionInfo.currentMap === 'line'){
                                                setMapType('map');

                                                setActionInfo({
                                                    ...actionInfo,
                                                    currentMap: 'map',
                                                })

                                            }

                                        }}
                                >区块名称</button>
                                <button
                                    className={actionInfo.currentMap === 'line' ? "selected-map-type" : ''}
                                    onClick={() => {
                                        if(actionInfo.currentMap === 'map'){
                                            // setAreaStatus(false);
                                            setAreaKey(undefined);

                                            setMapType('line');
                                            setActionInfo(JSON.parse(JSON.stringify({
                                                mapId: null,
                                                type: 'reset',
                                                isHandle: true,
                                                data: [],
                                                currentMap: 'line',
                                            })));

                                        }
                                    }}
                                >地铁线路</button>
                            </div>
                            <img src={HeaderBorder} />
                        </div>
                    </div>
                )
            }

            {
                orderFormStatus && (
                    <Draggable
                        cancel={'input,button,select,textarea,div,span'}
                    >
                        <section className="order-input-box">
                            <section className="order-input-main">
                                <header className="order-input-header">
                                    <b>空间信息编辑</b>
                                    <span onClick={() => {
                                        const temp = FormInitData;

                                        temp.cityInfo = {
                                            id: orderInfo.cityInfo.id,
                                            name: orderInfo.cityInfo.name,
                                        };
                                        setOrderInfo(JSON.parse(JSON.stringify(temp)));
                                        setOrderFormStatus(!orderFormStatus);

                                    }} />
                                </header>
                                <div className="order-input-form-box">
                                    <div className="order-input-form">
                                        <div className="order-position-name-number">
                                            <div className="order-position-name">
                                                <span>所属城区</span>
                                                <div>{orderInfo.cityInfo && orderInfo.cityInfo.name}</div>
                                            </div>
                                            <div className="order-position-number">
                                                <span>单元</span>
                                                <div>
                                                    <b><i /></b>
                                                    <select
                                                        style={{color: orderInfo.cityInfo.BillID ? 'rgb(170, 170, 170)' : ''}}
                                                        placeholder="请选择"
                                                        defaultValue={orderInfo.unitInfo.BlockID}
                                                        disabled={orderInfo.cityInfo.BillID}
                                                        onChange={(e) => {
                                                            const selectIndex: number = e.target.options.selectedIndex;
                                                            const temp: any = unitList[selectIndex];
                                                            temp['selectIdx'] = selectIndex;
                                                            setOrderInfo(JSON.parse(JSON.stringify({
                                                                ...orderInfo,
                                                                unitInfo: temp,
                                                            })))
                                                        }}
                                                    >
                                                        {
                                                            unitList.length > 0 && unitList.map((item: any,key: number) => {
                                                                return (
                                                                    <option
                                                                        className="order-position-option"
                                                                        key={item.Key} value={item.BlockID}
                                                                        disabled={item.Status == 1}
                                                                    >
                                                                        {item.Name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="order-zone-size-number">
                                            <div className="order-zone-size">
                                                <span>空间面积</span>
                                                <div>{orderInfo.zoneInfo.label}</div>
                                            </div>
                                            <div className="order-zone-number">
                                                <span>编号</span>
                                                <div>
                                                    <b><i /></b>
                                                    <select
                                                        placeholder="请选择"
                                                        style={{color: orderInfo.cityInfo.BillID ? 'rgb(170, 170, 170)' : ''}}
                                                        defaultValue={orderInfo.zoneInfo.value}
                                                        disabled={orderInfo.cityInfo.BillID}
                                                        onChange={(e) => {
                                                            const selectIndex: number = e.target.options.selectedIndex;
                                                            setOrderInfo(JSON.parse(JSON.stringify({
                                                                ...orderInfo,
                                                                zoneInfo: zoneList[selectIndex],
                                                            })))
                                                        }}
                                                    >
                                                        {
                                                            zoneList.map((item: any,key: number) => {
                                                                return (
                                                                    <option
                                                                        key={item.label}
                                                                        value={item.value}
                                                                        style={{color: "red!important"}}
                                                                    >{item.label}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="order-form-line"><span /></div>
                                        <div  className="order-zone-type">
                                            <span>空间类型</span>
                                            {
                                                zoneTypeList.map((item: any,key: number) => {
                                                    return (
                                                        <div
                                                            className={[
                                                                "order-zone-type-item",
                                                                orderInfo.zoneType === item.value ? 'checked-status' : ''
                                                            ].join(' ')}
                                                            key={item.label}
                                                            data-value={item.value}
                                                            onClick={(e: any) => {
                                                                let clickValue: number = parseInt(e.target.dataset.value);
                                                                if(clickValue === orderInfo.zoneType){
                                                                    clickValue = 0;
                                                                }

                                                                setOrderInfo(JSON.parse(JSON.stringify({
                                                                    ...orderInfo,
                                                                    zoneType: clickValue,
                                                                })));
                                                            }}
                                                        >{item.label}</div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="order-sale-type">
                                            <span>租售情况</span>
                                            {
                                                saleTypeList.map((item: any,key: number) => {
                                                    return (
                                                        <div
                                                            className={[
                                                                "order-sale-type-item",
                                                                orderInfo.saleType === item.value ? 'checked-status' : ''
                                                            ].join(' ')}
                                                            key={item.label}
                                                            data-value={item.value}
                                                            onClick={(e: any) => {
                                                                let clickValue: number = parseInt(e.target.dataset.value);
                                                                if(clickValue === orderInfo.saleType){
                                                                    clickValue = 0;
                                                                }

                                                                setOrderInfo(JSON.parse(JSON.stringify({
                                                                    ...orderInfo,
                                                                    saleType: clickValue,
                                                                })));
                                                            }}
                                                        >{item.label}</div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="order-sale-price">
                                            <span>租售价格</span>
                                            <input
                                                className="order-sale-input"
                                                type={orderInfo.showPrice ? 'text' : 'password'}
                                                defaultValue={orderInfo.price}
                                                disabled={!orderInfo.showPrice}
                                                onChange={(e) => {
                                                    let price = e.target.value.replace(/[^\d.]/g,"");//清除"数字"和"."以外的字符
                                                    price = price.replace(/^\./g,"");//验证第一个字符是数字而不是字符
                                                    price = price.replace(/\.{2,}/g,".");//只保留第一个.清除多余的
                                                    price = price.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
                                                    price = price.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');//只能输入两个小数

                                                    setOrderInfo(JSON.parse(JSON.stringify({
                                                        ...orderInfo,
                                                        price: price,
                                                    })));
                                                }}
                                                onKeyUp={(obj: any) => {
                                                    obj.target.value = obj.target.value.replace(/[^\d.]/g,"");//清除"数字"和"."以外的字符
                                                    obj.target.value = obj.target.value.replace(/^\./g,"");//验证第一个字符是数字而不是字符
                                                    obj.target.value = obj.target.value.replace(/\.{2,}/g,".");//只保留第一个.清除多余的
                                                    obj.target.value = obj.target.value.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
                                                    obj.target.value = obj.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');//只能输入两个小数

                                                    if(obj.target.value >= 99999999) {
                                                        obj.target.value = 99999999;
                                                    }
                                                }}
                                            />
                                            <div
                                                className={[
                                                    "order-sale-checkbox",
                                                    orderInfo.showPrice ? 'checked-status' : ''
                                                ].join(" ")}
                                                onClick={(e: any) => {
                                                    setOrderInfo(JSON.parse(JSON.stringify({
                                                        ...orderInfo,
                                                        showPrice: !orderInfo.showPrice,
                                                    })));
                                                }}
                                            >显示</div>
                                        </div>
                                        <div className="order-form-line"><span /></div>
                                        <div className="order-customer-info">
                                            <span>客户信息</span>
                                            <textarea
                                                placeholder="请输入"
                                                maxLength={100}
                                                defaultValue={orderInfo.info}
                                                onChange={(e) => {
                                                    setOrderInfo(JSON.parse(JSON.stringify({
                                                        ...orderInfo,
                                                        info: e.target.value,
                                                    })));
                                                }}
                                            />
                                        </div>
                                        <div className="order-customer-phone">
                                            <span>客户电话</span>
                                            <input
                                                type="text"
                                                placeholder="请输入"
                                                maxLength={11}
                                                defaultValue={orderInfo.phone}
                                                onChange={(e) => {
                                                    setOrderInfo(JSON.parse(JSON.stringify({
                                                        ...orderInfo,
                                                        phone: e.target.value,
                                                    })));
                                                }}
                                            />
                                        </div>

                                        <div className="order-input-submit">
                                            {
                                                orderInfo.cityInfo.BillID && (
                                                    <div className="order-input-del">
                                                        <span onClick={() => {
                                                            DelOrder(orderInfo.cityInfo.BillID);
                                                        }} />
                                                    </div>
                                                )
                                            }
                                            <div className="order-input-btn" style={{justifyContent: orderInfo.cityInfo.BillID ? 'flex-end' : 'space-around'}}>
                                                <span onClick={() => {
                                                    const temp = FormInitData;

                                                    temp.cityInfo = {
                                                        id: orderInfo.cityInfo.id,
                                                        name: orderInfo.cityInfo.name,
                                                    };
                                                    setOrderInfo(JSON.parse(JSON.stringify(temp)));
                                                    setOrderFormStatus(!orderFormStatus);

                                                }}>取消</span>
                                                <span onClick={() => {
                                                    orderSubmit();
                                                }}>保存</span>
                                            </div>
                                        </div>

                                        <div className="order-null-box" />
                                    </div>
                                </div>
                            </section>
                        </section>
                    </Draggable>
                )
            }
        </div>
    )
}

export default Main;